import { Layout, Divider, Card, Avatar, Switch, Input, Button } from "antd";
import * as React from "react";
import firebase from "firebase/app";

function User({
  doc,
}: {
  doc: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
}) {
  const [photoUrl, setPhotoUrl] = React.useState<string>();
  const setAdmin = React.useMemo(
    () => firebase.functions().httpsCallable("setAdmin"),
    []
  );

  React.useEffect(() => {
    firebase
      .storage()
      .ref()
      .child("avatars")
      .child(doc.id)
      .getDownloadURL()
      .then(setPhotoUrl);
  }, [doc.id]);

  return (
    <Card size="small" title={`@${doc.get("username")} - ${doc.id}`}>
      <div style={{ display: "flex" }}>
        {photoUrl ? (
          <Avatar src={photoUrl} size="large" />
        ) : (
          <Avatar size="large">{[...doc.get("name")][0]}</Avatar>
        )}
        <div style={{ marginLeft: "8px" }}>
          <p>Name: {doc.get("name")}</p>
          <p>
            Admin:{" "}
            <Switch
              checked={doc.get("isAdmin")}
              onChange={(admin) => setAdmin({ userId: doc.id, admin })}
            />
          </p>
          <p>
            Presenter:{" "}
            <Switch
              checked={doc.get("isPresenter")}
              onChange={(isPresenter) => doc.ref.update({ isPresenter })}
            />
          </p>
          <p>
            Verified:{" "}
            <Switch
              checked={doc.get("isVerified")}
              onChange={(isVerified) => doc.ref.update({ isVerified })}
            />
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <h3>Actions</h3>
          <Divider />
          <Button
            onClick={() => {
              const ts = prompt(
                "enter a timestamp, eg 2020-10-04T15:00:00-04:00"
              );
              const date = new Date(ts || "");
              if (!window.confirm("confirm the scheduled time: " + date)) {
                return;
              }
              const description = prompt("enter a description");
              firebase
                .firestore()
                .collection("huddles")
                .doc(doc.id)
                .set(
                  {
                    type: "live",
                    isActive: false,
                    description,
                    scheduled: firebase.firestore.Timestamp.fromDate(date),
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    presenterId: doc.id,
                    communityId: "huddle",
                  },
                  { merge: true }
                );
            }}
          >
            Schedule a Huddle
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default function () {
  const [users, setUsers] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  >([]);
  const [query, setQuery] = React.useState("");

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("users")
      .where("username", ">=", query)
      .where("username", "<", `${query}\uFFFF`)
      .limit(25)
      .onSnapshot((snapshot) => setUsers(snapshot.docs));
  }, [query]);

  return (
    <Layout.Content style={{ padding: "16px" }}>
      <h1>Users</h1>
      <Divider />
      <Input
        placeholder="Search"
        value={query}
        onInput={(e) => setQuery(e.currentTarget.value)}
      />
      {users.map((doc) => (
        <User key={doc.id} doc={doc} />
      ))}
    </Layout.Content>
  );
}
