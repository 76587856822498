import {
  FileOutlined,
  StopOutlined,
  DesktopOutlined,
  UserOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import firebase from "firebase/app";
import * as React from "react";
import { useHistory } from "react-router-dom";

export default function ({ children }: React.PropsWithChildren<{}>) {
  const history = useHistory();

  return (
    <Layout style={{ minHeight: "100%" }}>
      <Layout.Sider>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            key="overview"
            icon={<DesktopOutlined />}
            onClick={() => history.push("/")}
          >
            Overview
          </Menu.Item>
          <Menu.Item
            key="huddles"
            icon={<FileOutlined />}
            onClick={() => history.push("/huddles")}
          >
            Huddles
          </Menu.Item>
          <Menu.Item
            key="products"
            icon={<ShoppingOutlined />}
            onClick={() => history.push("/products")}
          >
            Products
          </Menu.Item>
          <Menu.Item
            key="users"
            icon={<UserOutlined />}
            onClick={() => history.push("/users")}
          >
            Users
          </Menu.Item>
          <Menu.Item
            key="sign-out"
            icon={<StopOutlined />}
            onClick={() => firebase.auth().signOut()}
          >
            Sign Out
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout>{children}</Layout>
    </Layout>
  );
}
