import * as React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Authentication from "./Authentication";
import Dashboard from "./Dashboard";
import Overview from "./Overview";
import Huddles from "./Huddles";
import Users from "./Users";
import Products from "./Products";

function App() {
  return (
    <BrowserRouter>
      <Authentication>
        <Switch>
          <Route path="/huddles">
            <Dashboard>
              <Huddles />
            </Dashboard>
          </Route>
          <Route path="/products">
            <Dashboard>
              <Products />
            </Dashboard>
          </Route>
          <Route path="/users">
            <Dashboard>
              <Users />
            </Dashboard>
          </Route>
          <Route path="/">
            <Dashboard>
              <Overview />
            </Dashboard>
          </Route>
        </Switch>
      </Authentication>
    </BrowserRouter>
  );
}

export default App;
