import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Layout,
  AutoComplete,
  Tabs,
} from "antd";
import firebase from "firebase/app";
import * as React from "react";
import Hls from "hls.js";
import algolia from "./algolia";

function CreateCampaign({ huddleId }: { huddleId: string }) {
  const index = React.useMemo(() => algolia.initIndex("products"), []);
  const [options, setOptions] = React.useState<
    { key: string; value: string }[]
  >([]);
  const [productId, setProductId] = React.useState<string>();

  return (
    <div style={{ display: "flex" }}>
      <AutoComplete
        style={{ flex: "1 1 auto" }}
        options={options}
        onSearch={async (text) => {
          const results = await index.search(text, { length: 10 });
          setOptions(
            results.hits.map((hit) => {
              return { key: hit.objectID, value: (hit as any)["name"] };
            })
          );
        }}
        onSelect={(value) => {
          const option = options.find((option) => option.value === value);
          if (option) {
            setProductId(option.key);
          }
        }}
      />
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (!productId) {
            return;
          }
          firebase.firestore().collection("campaigns").add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            deleted: null,
            huddleId,
            productId,
          });
        }}
      >
        Add
      </Button>
    </div>
  );
}

function Campaign({
  campaign,
}: {
  campaign: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
}) {
  const [product, setProduct] = React.useState<
    firebase.firestore.DocumentSnapshot
  >();

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("products")
      .doc(campaign.get("productId"))
      .onSnapshot(setProduct);
  }, [campaign]);

  return (
    <div style={{ display: "flex" }}>
      <div>{campaign.get("created").toDate().toString()}</div>
      <div style={{ flex: "1 1 auto", margin: "0 16px" }}>
        {product?.get("name")}
      </div>
      {campaign.get("deleted") ? (
        <div>deleted</div>
      ) : (
        <Button
          onClick={() => {
            campaign.ref.update({
              deleted: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }}
        >
          Stop
        </Button>
      )}
    </div>
  );
}

function Huddle({
  huddle,
}: {
  huddle: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
}) {
  const [user, setUser] = React.useState("");
  const [campaigns, setCampaigns] = React.useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const presenterId = huddle.get("presenterId");
  const sourceUrl = huddle.get("sourceUrl");
  const description = huddle.get("description");

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("users")
      .doc(presenterId)
      .onSnapshot((doc) => setUser(doc.get("username")));
  }, [presenterId]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = sourceUrl;
    } else if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(sourceUrl);
      hls.attachMedia(video);
    }
  }, [videoRef, sourceUrl]);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("campaigns")
      .where("huddleId", "==", huddle.id)
      .onSnapshot((snapshot) => {
        setCampaigns(snapshot.docs);
      });
  }, [huddle.id]);

  return (
    <Card
      title={`@${user} - /${huddle.get("communityId")} - ${huddle.get(
        "type"
      )} - active: ${huddle.get("isActive")} - ${
        huddle.id
      } - description: ${description} - scheduled: ${huddle
        .get("scheduled")
        ?.toDate()}`}
    >
      <div style={{ display: "flex" }}>
        <div style={{}}>
          {huddle.get("type") === "recorded" && (
            <video muted ref={videoRef} controls style={{ height: "400px" }} />
          )}
        </div>
        <div style={{ flex: "2 1 auto", marginLeft: "16px" }}>
          <h3>Campaigns</h3>
          <Divider />
          <CreateCampaign huddleId={huddle.id} />
          <Divider />
          {campaigns.map((campaign) => (
            <Campaign campaign={campaign} />
          ))}
        </div>
        <div style={{ flex: "1 1 auto", marginLeft: "16px" }}>
          <h3>Actions</h3>
          <Divider />
          <Button onClick={() => huddle.ref.update({ isActive: false })}>
            Disable
          </Button>
          <Button onClick={() => huddle.ref.update({ isActive: true })}>
            Enable
          </Button>
          <Button
            onClick={() => {
              const description = prompt("enter new description");
              huddle.ref.update({ description });
            }}
          >
            Set Description
          </Button>
          <Button
            onClick={() => {
              const communityId = prompt(
                "enter new community id (NO SLASH, CASE SENSITIVE)"
              );
              huddle.ref.update({ communityId });
            }}
          >
            Set Community
          </Button>
        </div>
      </div>
    </Card>
  );
}

function CreateRecordedHuddle() {
  const [form] = Form.useForm();
  const fileInput = React.useRef<HTMLInputElement>(null);
  const [options, setOptions] = React.useState<
    { key: string; value: string }[]
  >([]);

  return (
    <Card title="Add a Recorded Huddle">
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={async ({ communityId, presenter }) => {
          const presenterId = options.find(
            (option) => option.value === presenter
          )?.key;
          if (!fileInput.current || !presenterId) {
            return;
          }
          const firestoreRef = firebase.firestore().collection("huddles").doc();
          const storageRef = firebase
            .storage()
            .ref()
            .child("huddles")
            .child(firestoreRef.id);
          await storageRef.put(fileInput.current.files![0]);
          const sourceUrl = await storageRef.getDownloadURL();
          await firestoreRef.set({
            communityId,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isActive: false,
            isPrivate: false,
            type: "recorded",
            presenterId,
            sourceUrl,
          });

          form.resetFields();
        }}
      >
        <Form.Item
          label="Presenter"
          name="presenter"
          rules={[{ required: true, message: "Required" }]}
        >
          <AutoComplete
            options={options}
            onSearch={async (text) => {
              const query = await firebase
                .firestore()
                .collection("users")
                .where("username", ">=", text)
                .where("username", "<", text + "\uFFFF")
                .get();
              setOptions(
                query.docs.map((doc) => ({
                  key: doc.id,
                  value: doc.get("username"),
                }))
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="Community"
          name="communityId"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input addonBefore="#" placeholder="huddle" />
        </Form.Item>
        <Form.Item
          label="Video"
          name="video"
          rules={[{ required: true, message: "Required" }]}
        >
          <input type="file" ref={fileInput} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default function () {
  const [inactiveHuddles, setInactiveHuddles] = React.useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);
  const [activeHuddles, setActiveHuddles] = React.useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);
  const [scheduledHuddles, setScheduleHuddles] = React.useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("huddles")
      .where("isActive", "==", false)
      .orderBy("created", "desc")
      .limit(25)
      .onSnapshot((snapshot) => setInactiveHuddles(snapshot.docs));
  }, []);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("huddles")
      .where("isActive", "==", true)
      .orderBy("created", "desc")
      .limit(25)
      .onSnapshot((snapshot) => setActiveHuddles(snapshot.docs));
  }, []);

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("huddles")
      .orderBy("scheduled", "asc")
      .where("scheduled", "!=", null)
      .orderBy("created", "desc")
      .limit(25)
      .onSnapshot((snapshot) => setScheduleHuddles(snapshot.docs));
  }, []);

  return (
    <Layout.Content style={{ padding: "16px" }}>
      <h1>Huddles</h1>
      <Divider />
      <CreateRecordedHuddle />
      <Divider />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Active Huddles" key="1">
          {activeHuddles.map((huddle) => (
            <Huddle key={huddle.id} huddle={huddle} />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inactive Huddles" key="2">
          {inactiveHuddles.map((huddle) => (
            <Huddle key={huddle.id} huddle={huddle} />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Scheduled Huddles" key="3">
          {scheduledHuddles.map((huddle) => (
            <Huddle key={huddle.id} huddle={huddle} />
          ))}
        </Tabs.TabPane>
      </Tabs>
      <Divider />
    </Layout.Content>
  );
}
