import firebase from "firebase/app";
import * as React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useLocation } from "react-router-dom";

export default function ({ children }: React.PropsWithChildren<{}>) {
  const [authorized, setAuthorized] = React.useState<boolean>();
  const [requested, setRequested] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(
      async (user) => {
        if (!user) {
          setAuthorized(false);
          setRequested(false);
        } else {
          console.log("checking");
          const token = await user.getIdTokenResult();
          console.log(token.claims);
          const authorized = Boolean(token.claims["admin"]);
          setAuthorized(authorized);
          setRequested(true);
        }
      },
      (err) => console.error(err)
    );
  }, []);

  if (authorized === undefined) {
    return null;
  }
  if (!authorized) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>{requested ? "Not authorized" : "Hey man."}</p>
        <StyledFirebaseAuth
          uiConfig={{
            signInFlow: "popup",
            signInSuccessUrl: location.pathname,
            signInOptions: [
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
          }}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }
  return <>{children}</>;
}
