import { Breadcrumb, Layout } from "antd";
import * as React from "react";

export default function () {
  return (
    <>
      <Layout.Header
        className="site-layout-background"
        style={{ padding: 0 }}
      />
      <Layout.Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Bill</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          Bill is a cat.
        </div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: "center" }}>
        Ant Design ©2018 Created by Ant UED
      </Layout.Footer>
    </>
  );
}
