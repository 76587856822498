import { Button, Card, Divider, Form, Input, Layout } from "antd";
import firebase from "firebase/app";
import * as React from "react";
import algolia from "./algolia";

function Product({ productId }: { productId: string }) {
  const [product, setProduct] = React.useState<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  >();

  React.useEffect(() => {
    return firebase
      .firestore()
      .collection("products")
      .doc(productId)
      .onSnapshot(setProduct);
  }, [productId]);

  if (!product) {
    return null;
  }
  return (
    <Card title={product.get("name")}>
      <img height="250px" src={product.get("imageUrl")} alt="product" />
    </Card>
  );
}

function CreateProduct() {
  const [form] = Form.useForm();
  const fileInput = React.useRef<HTMLInputElement>(null);

  return (
    <Card title="Add a Product">
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={async ({ name, price, url }) => {
          if (!fileInput.current) {
            return;
          }
          const firestoreRef = firebase
            .firestore()
            .collection("products")
            .doc();
          const storageRef = firebase
            .storage()
            .ref()
            .child("products")
            .child(firestoreRef.id);
          await storageRef.put(fileInput.current.files![0]);
          const imageUrl = await storageRef.getDownloadURL();
          await firestoreRef.set({
            name,
            price: { USD: price },
            url,
            imageUrl,
          });

          form.resetFields();
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Mugit-brand Cow Product" />
        </Form.Item>
        <Form.Item
          label="Price (dollars only)"
          name="price"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input addonBefore="$" placeholder="24" />
        </Form.Item>
        <Form.Item
          label="Sales URL"
          name="url"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="https://www.smashbox.com/..." />
        </Form.Item>
        <Form.Item
          label="Picture"
          name="picture"
          rules={[{ required: true, message: "Required" }]}
        >
          <input type="file" ref={fileInput} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default function () {
  const [productIds, setProductIds] = React.useState<string[]>([]);
  const [query, setQuery] = React.useState("");

  const index = React.useMemo(() => algolia.initIndex("products"), []);

  React.useEffect(() => {
    index.search(query, { length: 25 }).then(({ hits }) => {
      setProductIds(hits.map((hit) => hit.objectID));
    });
  }, [index, query]);

  return (
    <Layout.Content style={{ padding: "16px" }}>
      <h1>Products</h1>
      <Divider />
      <CreateProduct />
      <Divider />
      <Input
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />
      {productIds.map((productId) => (
        <Product key={productId} productId={productId} />
      ))}
    </Layout.Content>
  );
}
