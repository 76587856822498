import * as React from "react";
import * as ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

firebase.initializeApp({
  apiKey: "AIzaSyD7a185Ejpq1vJ_7ATUpejnYm-fdhdGW-U",
  authDomain: "huddle-9d4cc.firebaseapp.com",
  databaseURL: "https://huddle-9d4cc.firebaseio.com",
  projectId: "huddle-9d4cc",
  storageBucket: "huddle-9d4cc.appspot.com",
  messagingSenderId: "179968605371",
  appId: "1:179968605371:web:010866306a97aa6c41fd9e",
  measurementId: "G-R56R6ZJM5K",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
